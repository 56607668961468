import api from "../../utils/api";

export function getInfoMessage(params) {
    return api('/ca/onlc/order/init-cert-order', {
        method: 'POST',
        params: params
    })
}

export function getPrice(params) {
  return api('/ca/onlc/price/search', {
      method: 'POST',
      params: {...params}
  })
}