<template>
  <div class="delayBox">
    <h1 class="title">在线延期</h1>
    <div style="margin-left: -100px">
      <el-form ref="form" :rules="ruleForm" :model="form" label-width="110px">
        <el-form-item prop="certificate" label="选择证书" style="position: relative">
          <el-select v-model="form.certificate" class="el_input" placeholder="请插入Ukey选择相应的证书">
            <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item" />
          </el-select>
          <div style="float: right; position: absolute; right: -125px; top: -1px">
            <el-button style="width: 116px; height: 33px" size="small" type="primary" @click="getCertificate"
              :loading="pkiLoading">
              获取设备信息
            </el-button>
          </div>
        </el-form-item>
        <div v-if="isShow">
          <el-form-item class="el-setMeal" label="选择延期年限" prop="checkSetMeal" v-if="isYear">
            <el-radio-group v-model="form.checkSetMeal" size="mini">
              <el-radio v-for="(item, index) in yearOptions" :key="index" :label="item.value" border>{{ item.label
                }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="isSeal" class="el-setMeal" label="是否带章" prop="isSeal">
            <el-radio-group v-model="form.isSeal" size="mini">
              <el-radio v-for="(item, index) in sealOptions" :key="index" :label="item.value" border>{{ item.label
                }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优惠券码" prop="voucher">
            <el-input class="el_voucher" placeholder="请输入优惠券码" v-model="voucher"></el-input>
            <div style=" position: absolute; right: 20px; top: 0">
              <el-button style="width: 70px; height: 33px" size="small" type="primary" @click="define">
                确定
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="实付金额">

            <div class="price"><span class="sign">￥</span> <span class="totalPrice"> {{ totalPrice }}</span>
              <span class="originalPrice" v-if="originalPrice != totalPrice">( 原价：￥{{ originalPrice }} ) </span>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div style="text-align: center">
      <el-button :disabled="isDisabled" @click="routerPush" :type="!isDisabled ? 'primary' : 'info'" :plain="isDisabled"
        :loading="loading" class="btn">
        下一步
      </el-button>
      <p class="note">
        若当前需要支付，您需要点击“下一步”完成支付后进行延期，若无需支付，则可以直接点击“下一步”进行延期操作
      </p>
    </div>
  </div>
</template>
<script>
import PKIDriver, { getProBySubject } from "../../utils/pki-driver";
import { setGoodsList } from "../../utils/public-instrument";
import { isCertificate } from "./methods";
import { getInfoMessage, getPrice } from "./severs";
import {MessageBox} from 'element-ui'
export default {
  data() {
    return {
      isFlag: 'PAID',
      isShow: false,
      isYear: false,
      isSeal: false,
      loading: false,
      options: [],
      yearOptions: [],
      sealOptions: [],
      form: {
        certificate: undefined,
        checkSetMeal: undefined,
        isSeal: undefined,
        voucher: undefined,
      },
      ruleForm: {
        certificate: [
          { required: true, message: "请选择证书", trigger: "blur" },
        ],
        checkSetMeal: [
          { required: true, message: "请选择延期年限", trigger: "blur" },
        ],
        isSeal: [
          { required: true, message: "请选择是否带章", trigger: "blur" },
        ],
      },
      serialNumber: "1231313",
      ou: "",
      certId: "",
      goodsId: "",
      orderId: "",
      certOperationType: '',
      voucher: '',
      originalPrice: '0',
      totalPrice: '0',
      fingerprint: '',
      pkiLoading: false,
      isDisabled: true,//下一步是否禁用
      infoLoading: false,

    };
  },

  methods: {
    noPayOrderMethod(){
    setTimeout(()=>{
    this.$router.push('/postpone/orderForm')
    MessageBox.close()
    },2000)

    MessageBox.alert('您有未支付的订单，请在订单查询页面查询并支付，订单页面跳转中，请稍等。。。', {
      showConfirmButton:false,
      showClose:false
    });
    },

    getCertificate() {
      this.infoLoading=true
      this.pkiLoading = true
      const pkiDriver = this.getPkiDriver();
      pkiDriver.getCertInfo().then(async (res) => {
        this.serialNumber = res.SerialNumber;
        const DeviceSN = res.DeviceSN;
        localStorage.setItem("DeviceSN", DeviceSN);
        this.ou = getProBySubject(res.Subject, "ou");
        const cn = getProBySubject(res.Subject, "cn");
        this.form.certificate = cn;
        this.certId = getProBySubject(res.Subject, "x500UniqueIdentifier");
        this.options = [];
        this.options.push(cn);
        this.fingerprint = res.ThumbprintSHA1
        // 判断数据不为空
        const result = isCertificate([this.certId, this.serialNumber, this.ou, this.fingerprint]);
        const params = {
          certId: this.certId,
          ou: this.ou,
          serialNumber: this.serialNumber,
          fingerprint: this.fingerprint
        };
        if (result) {

          this.loading = true
          getInfoMessage(params)
            .then((res) => {
              this.isDisabled = false
              this.loading = false
              const { goodsId, orderId, certOperationType } = res.data
              this.goodsId = goodsId
              this.certOperationType = certOperationType
              setGoodsList(res.data)
              if (!orderId) {
                this.isShow = true;
                if (
                  res.data?.specifications.some(
                    (obj) => obj.specKey === "DURATION"
                  )
                ) {
                  this.isYear = true;
                  const obj = res.data?.specifications.find(
                    (obj) => obj.specKey === "DURATION"
                  );
                  // 获取排序后的键数组
                  let keys = Object.keys(obj.options).sort();
                  // 创建具有指定结构的数组
                  let finalArray = keys.map((key) => ({
                    label: key,
                    value: obj.options[key],
                  }));

                  finalArray.sort(function (a, b) {
                    return Number(a.value) - Number(b.value);
                  });
                  this.yearOptions = finalArray;
                }
                if (
                  res.data?.specifications.some((obj) => obj.specKey === "SEAL")
                ) {
                  this.isSeal = true;
                  const obj = res.data?.specifications.find(
                    (obj) => obj.specKey === "SEAL"
                  );
                  // 获取对象的值数组
                  let values = Object.values(obj.options);

                  // 创建具有指定结构的数组
                  let finalArray = values.map((value, index) => ({
                    label: Object.keys(obj.options)[index],
                    value: value,
                  }));
                  this.sealOptions = finalArray

                }
              }
              if (orderId) {
                this.orderId = orderId
                if (res.data.orderStatus == 'TO_BE_PAID') {
                  this.isFlag = 'PAID'
                  this.isDisabled=true
                  this.noPayOrderMethod()
                }
                if (res.data.orderStatus == 'TO_BE_POSTPONED') {
                  this.isFlag = 'POSTPONED'
                }
                if (res.data.orderStatus == 'TO_BE_INVOICED') {
                  this.isFlag = 'INVOICED'
                }
                if (res.data.orderStatus == 'COMPLETED') {
                  this.isFlag = 'COMPLETED'
                }
              }

            })
            .catch(() => {
              this.loading = false
              this.pkiLoading = false
            })
            .finally(() => {
              this.loading = false
              this.pkiLoading = false
            })
        }
      }).catch((error)=>{
        this.pkiLoading = false
              this.$message({
                message: error.errorMsg,
                type: "error",
              });

      })
    },
    getPkiDriver() {
      if (this.pkiDriver) {
        return this.pkiDriver;
      }
      this.pkiDriver = new PKIDriver();
      return this.pkiDriver;
    },
    routerPush() {
      if (this.isFlag == 'PAID') {
        const form = this.$refs.form
        form.validate((valid) => {
          if (!valid) {
            return false
          }
          this.$router.push({
            name: "pay",
            query: {
              certificate: this.form.certificate,
              checkSetMeal: this.form.checkSetMeal || '',
              voucher: this.form.voucher || '',
              isSeal: this.form.isSeal ?? '',
              goodsId: this.goodsId,
              orderId: this.orderId || '',
              fingerprint: this.fingerprint,
              originalPrice: this.originalPrice,
              totalPrice: this.totalPrice
            },
          });
        })

      } else if (this.isFlag == 'POSTPONED') {
        this.$router.push({
          name: "operation",
          query: {
            orderId: this.orderId
          },
        });
      } else {
        this.$router.push({
          name: "orderForm",
        });
      }
    },
    define() {
      if (this.voucher != '') {
        this.form.voucher = this.voucher
      }
    },
    async fetchPrice(params) {
      try {
        const res = await getPrice(params);
        const { originalPrice, totalPrice } = res.data
        this.originalPrice = originalPrice
        this.totalPrice = totalPrice
      } catch (error) {

      }
    },
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {

        const { checkSetMeal, isSeal, voucher } = newVal
        const extend = { DURATION: checkSetMeal, SEAL: isSeal };
        if (checkSetMeal != undefined || isSeal != undefined || voucher != undefined) {
          this.fetchPrice({ goodsId: this.goodsId, extend: extend, voucher: voucher, certOperationType: this.certOperationType });
        }
      },
      deep: true
    }
  },
  mounted() {

  },

};
</script>
<style scoped>
:deep(.el-button--primary) {
  background-color: #2086fa;
  border-color: #2086fa;
}

:deep(.el-input__inner) {
  height: 33px;
  /* width: 400px; */
}

:deep(.el-radio--mini.is-bordered) {
  height: 33px;
  width: 60px;
  padding-top: 9px;
  text-align: center;
}

:deep(.el-radio__inner) {
  display: none;
}

:deep(.el-radio) {
  margin-right: 5px
}

.price {
  display: flex;
}

.sign {
  margin-top: 2px;
  color: #2086fa;
}

.originalPrice {
  color: #bfc3c7;
  font-size: 13px;
}

.totalPrice {
  font-size: 20px;
  color: #2086fa;
}

.el_input {
  width: 400px;
}

.el_voucher {
  width: 300px;
}

.delayBox {
  /* height: 1000px; */
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  /* text-align: center; */
}

.title h1 {
  width: 78px;
  height: 19px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #313438;
  line-height: 42px;
}

span {
  display: block;
  padding: 0;
  margin: 0;
}

.btn {
  margin-top: 20px;
  /* background: #f2f6fa; */
  font-weight: 500;
  padding: 9px 20px;
  font-size: 14px;
  border-radius: 4px;
  width: 150px;
  height: 32px;
}

.note {
  margin-left: 70px;
  margin-top: 20px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #bfc3c7;
}
</style>