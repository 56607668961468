import { Message } from 'element-ui'

//判断证书非空
export const isCertificate = (arr) => {
    if (!Array.isArray(arr)) return []
    for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
            Message({
                message: ' 证书id不存在,请联系客服',
                type: "error",
            });
            return false
        } else {
            return true
        }
    }
}

